.tech-base
{ 
    display: flex;
    align-items: center;
    &::before {
        content:'';
        display: inline-block; background: url("/assest/tech-img.png") no-repeat; overflow: hidden; text-indent: -9999px; text-align: left;
    }

    }

.tech-1c-bitrix {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -0px; } }
.tech-3dcart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -0px; } }
.tech-91app {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -0px; } }
.a-frame {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -0px; } }
.adplan {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -0px; } }
.aolserver {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -0px; } }
.at-internet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -0px; } }
.awstats {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -0px; } }
.accelerated-mobile-pages {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -0px; } }
.acton {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -0px; } }
.adinfinity {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -0px; } }
.addshoppers {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -0px; } }
.addthis {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -0px; } }
.addtoany {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -0px; } }
.adobe-coldfusion {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -0px; } }
.adobe-experience-manager {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -0px; } }
.adobe-golive {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -0px; } }
.adobe-muse {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -0px; } }
.adobe-robohelp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -0px; } }
.advanced-web-stats {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -0px; } }
.advert-stream {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -0px; } }
.adyen {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -0px; } }
.adzerk {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -0px; } }
.aegea {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -0px; } }
.afosto {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -0px; } }
.airee {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -0px; } }
.algolia-realtime-search {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -0px; } }
.allegro-rompager {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -0px; } }
.alloyui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -0px; } }
.amaya {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -0px; } }
.amazon-cloudfront {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -0px; } }
.ametys {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -0px; } }
.amiro-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -0px; } }
.anetwork {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -0px; } }
.angular {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -0px; } }
.angulardart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -0px; } }
.angularjs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -0px; } }
.ant-design {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -0px; } }
.apache-hbase {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -0px; } }
.apache-hadoop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -0px; } }
.apache-jspwiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -0px; } }
.apache-tomcat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -0px; } }
.apache-traffic-server {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -26px; } }
.apache-wicket {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -26px; } }
.apache {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -26px; } }
.apexpages {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -26px; } }
.appnexus {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -26px; } }
.appcues {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -26px; } }
.apple {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -26px; } }
.arastta {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -26px; } }
.artifactory {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -26px; } }
.arvancloud {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -26px; } }
.asciidoc {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -26px; } }
.atlassian-bitbucket {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -26px; } }
.atlassian-confluence {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -26px; } }
.atlassian-fisheye {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -26px; } }
.atlassian-jira {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -26px; } }
.aurelia {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -26px; } }
.avangate {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -26px; } }
.bem {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -26px; } }
.bigace {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -26px; } }
.backbone-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -26px; } }
.backdrop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -26px; } }
.backtory {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -26px; } }
.banshee {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -26px; } }
.basehttp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -26px; } }
.bigcommerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -26px; } }
.bigware {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -26px; } }
.bittads {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -26px; } }
.blade {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -26px; } }
.blesta {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -26px; } }
.blip-tv {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -26px; } }
.blogger {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -26px; } }
.bloomreach {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -26px; } }
.bluefish {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -26px; } }
.boldchat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -26px; } }
.bolt {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -26px; } }
.bonfire {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -26px; } }
.bootstrap-table {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -26px; } }
.bootstrap {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -26px; } }
.bounce-exchange {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -26px; } }
.braintree {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -26px; } }
.brightspot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -26px; } }
.browsercms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -26px; } }
.bugsnag {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -52px; } }
.bugzilla {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -52px; } }
.bulma {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -52px; } }
.burning-board {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -52px; } }
.business-catalyst {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -52px; } }
.cdn77 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -52px; } }
.cfml {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -52px; } }
.ckeditor {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -52px; } }
.cms-made-simple {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -52px; } }
.cpg-dragonfly {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -52px; } }
.cs-cart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -52px; } }
.cachefly {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -52px; } }
.cakephp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -52px; } }
.captch-me {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -52px; } }
.carbon-ads {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -52px; } }
.cargo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -52px; } }
.catberry-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -52px; } }
.cecil {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -52px; } }
.centos {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -52px; } }
.chameleon {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -52px; } }
.chamilo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -52px; } }
.chart-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -52px; } }
.chartbeat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -52px; } }
.cherokee {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -52px; } }
.cherrypy {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -52px; } }
.chitika {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -52px; } }
.ckan {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -52px; } }
.clickheat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -52px; } }
.clicktale {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -52px; } }
.clicky {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -52px; } }
.clientexec {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -52px; } }
.clipboard-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -52px; } }
.cloudflare {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -52px; } }
.cloudera {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -52px; } }
.codeigniter {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -52px; } }
.codemirror {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -52px; } }
.coinhive {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -52px; } }
.comandia {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -52px; } }
.commerce-server {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -52px; } }
.concrete5 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -52px; } }
.connect {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -52px; } }
.contao {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -52px; } }
.contenido {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -78px; } }
.contensis {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -78px; } }
.contentbox {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -78px; } }
.contentful {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -78px; } }
.conversionlab {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -78px; } }
.coppermine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -78px; } }
.cosmoshop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -78px; } }
.cotonti {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -78px; } }
.couchdb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -78px; } }
.cowboy {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -78px; } }
.cppcms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -78px; } }
.craft-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -78px; } }
.crazy-egg {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -78px; } }
.criteo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -78px; } }
.cross-pixel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -78px; } }
.crypto-loot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -78px; } }
.cubecart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -78px; } }
.cufon {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -78px; } }
.d3 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -78px; } }
.dhtmlx {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -78px; } }
.dm-polopoly {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -78px; } }
.dnn {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -78px; } }
.dtg {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -78px; } }
.dancer {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -78px; } }
.danneo-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -78px; } }
.dart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -78px; } }
.datalife-engine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -78px; } }
.datatables {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -78px; } }
.day-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -78px; } }
.debian {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -78px; } }
.dedecms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -78px; } }
.directadmin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -78px; } }
.discourse {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -78px; } }
.discuz-x {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -78px; } }
.disqus {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -78px; } }
.django-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -78px; } }
.django {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -78px; } }
.docker {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -78px; } }
.dojo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -78px; } }
.dokeos {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -78px; } }
.dokuwiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -78px; } }
.dotclear {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -78px; } }
.doubleclick {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -104px; } }
.dovetailwrp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -104px; } }
.doxygen {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -104px; } }
.dreamweaver {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -104px; } }
.drupal-commerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -104px; } }
.drupal {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -104px; } }
.dynamicweb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -104px; } }
.dynatrace {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -104px; } }
.elog {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -104px; } }
.episerver {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -104px; } }
.eprints {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -104px; } }
.easyengine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -104px; } }
.edgecast {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -104px; } }
.elcodi {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -104px; } }
.eleanor-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -104px; } }
.elementui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -104px; } }
.elementor {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -104px; } }
.embedthis {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -104px; } }
.ember-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -104px; } }
.envoy {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -104px; } }
.enyo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -104px; } }
.epom {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -104px; } }
.erlang {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -104px; } }
.exhibit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -104px; } }
.express {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -104px; } }
.expressionengine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -104px; } }
.extjs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -104px; } }
.fast-esp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -104px; } }
.fast-search-for-sharepoint {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -104px; } }
.fwp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -104px; } }
.facebook {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -104px; } }
.fact-finder {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -104px; } }
.fancybox {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -104px; } }
.fastcommerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -104px; } }
.fastly {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -104px; } }
.fat-free-framework {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -104px; } }
.fbits {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -104px; } }
.fedora {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -104px; } }
.firebase {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -104px; } }
.fireblade {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -104px; } }
.flask {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -104px; } }
.flat-ui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -104px; } }
.flexcmp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -130px; } }
.flexslider {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -130px; } }
.fluxbb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -130px; } }
.flyspray {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -130px; } }
.font-awesome {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -130px; } }
.forkcms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -130px; } }
.fortune3 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -130px; } }
.freebsd {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -130px; } }
.freetextbox {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -130px; } }
.freespee {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -130px; } }
.froala {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -130px; } }
.frontpage {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -130px; } }
.fusion-ads {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -130px; } }
.g-wan {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -130px; } }
.gx-webmanager {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -130px; } }
.gallery {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -130px; } }
.gambio {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -130px; } }
.gatsby {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -130px; } }
.gauges {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -130px; } }
.gentoo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -130px; } }
.get-satisfaction {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -130px; } }
.getsimple-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -130px; } }
.ghost {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -130px; } }
.gitbook {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -130px; } }
.github {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -130px; } }
.gitlab-ci {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -130px; } }
.gitlab {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -130px; } }
.glassfish {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -130px; } }
.glyphicons {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -130px; } }
.go {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -130px; } }
.goahead {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -130px; } }
.gojs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -130px; } }
.gostats {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -130px; } }
.google-adsense {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -130px; } }
.google-analytics {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -130px; } }
.google-app-engine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -130px; } }
.google-charts {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -130px; } }
.google-font-api {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -130px; } }
.google-maps {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -130px; } }
.google-pagespeed {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -130px; } }
.google-plus {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -130px; } }
.google-sites {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -130px; } }
.google-tag-manager {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -156px; } }
.google-wallet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -156px; } }
.google-web-toolkit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -156px; } }
.google {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -156px;}
.graffiti-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -156px; } }
.grav {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -156px; } }
.gravatar {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -156px; } }
.green-valley-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -156px; } }
.here {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -156px; } }
.hhvm {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -156px; } }
.hp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -156px; } }
.halo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -156px; } }
.hammer-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -156px; } }
.handlebars {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -156px; } }
.haravan {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -156px; } }
.haskell {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -156px; } }
.headjs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -156px; } }
.heap {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -156px; } }
.hello-bar {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -156px; } }
.hexo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -156px; } }
.hiawatha {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -156px; } }
.highcharts {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -156px; } }
.highlight-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -156px; } }
.hogan-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -156px; } }
.homeland {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -156px; } }
.hotaru-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -156px; } }
.hotjar {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -156px; } }
.hubspot-crm {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -156px; } }
.hubspot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -156px; } }
.hugo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -156px; } }
.hybris {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -156px; } }
.ibm {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -156px; } }
.iis {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -156px; } }
.infonline {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -156px; } }
.ipb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -156px; } }
.ideasoft {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -156px; } }
.immutable-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -156px; } }
.impresscms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -156px; } }
.impresspages {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -156px; } }
.inproces {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -156px; } }
.incapsula {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -156px; } }
.includable {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -156px; } }
.indico {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -182px; } }
.infernojs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -182px; } }
.instabot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -182px; } }
.instantcms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -182px; } }
.intel-active-management-technology {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -182px; } }
.intensedebate {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -182px; } }
.intercom {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -182px; } }
.intershop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -182px; } }
.invenio {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -182px; } }
.ionicons {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -182px; } }
.jalbum {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -182px; } }
.jboss-application-server {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -182px; } }
.jboss-web {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -182px; } }
.jet-enterprise {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -182px; } }
.js-charts {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -182px; } }
.jsecoin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -182px; } }
.jtl-shop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -182px; } }
.jahiadx {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -182px; } }
.jalios {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -182px; } }
.java {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -182px; } }
.javascript-infovis-toolkit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -182px; } }
.javaserver-faces {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -182px; } }
.jekyll {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -182px; } }
.jenkins {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -182px; } }
.jetshop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -182px; } }
.jetty {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -182px; } }
.jirafe {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -182px; } }
.jive {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -182px; } }
.jobberbase {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -182px; } }
.joomla {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -182px; } }
.k2 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -182px; } }
.kissmetrics {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -182px; } }
.kajabi {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -182px; } }
.kampyle {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -182px; } }
.kamva {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -182px; } }
.kendo-ui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -182px; } }
.kentico-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -182px; } }
.keycdn {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -182px; } }
.kineticjs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -182px; } }
.klarna {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -182px; } }
.knockout-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -182px; } }
.koa {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -182px; } }
.koala-framework {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -208px; } }
.kobimaster {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -208px; } }
.kohana {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -208px; } }
.koken {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -208px; } }
.komodo-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -208px; } }
.kontaktify {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -208px; } }
.koobi {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -208px; } }
.kooboo-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -208px; } }
.kotisivukone {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -208px; } }
.kubernetes {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -208px; } }
.lepton {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -208px; } }
.lou {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -208px; } }
.labview {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -208px; } }
.laravel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -208px; } }
.leaflet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -208px; } }
.less {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -208px; } }
.liferay {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -208px; } }
.lift {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -208px; } }
.lightmon-engine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -208px; } }
.lightbox {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -208px; } }
.lightspeed {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -208px; } }
.lighty {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -208px; } }
.limesurvey {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -208px; } }
.linksmart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -208px; } }
.linkedin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -208px; } }
.list-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -208px; } }
.litespeed {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -208px; } }
.lithium {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -208px; } }
.liveagent {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -208px; } }
.livechat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -208px; } }
.livehelp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -208px; } }
.livejournal {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -208px; } }
.liveperson {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -208px; } }
.livestreet-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -208px; } }
.livefyre {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -208px; } }
.liveinternet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -208px; } }
.lo-dash {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -208px; } }
.locomotive {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -208px; } }
.logitech-media-server {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -208px; } }
.lotus-domino {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -208px; } }
.lua {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -208px; } }
.lucene {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -208px; } }
.luigisbox {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -234px; } }
.m-r-inc {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -234px; } }
.modx {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -234px; } }
.madadsmedia {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -234px; } }
.magento {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -234px; } }
.makeshopkorea {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -234px; } }
.mambo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -234px; } }
.mantisbt {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -234px; } }
.manycontacts {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -234px; } }
.marionette-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -234px; } }
.marketo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -234px; } }
.material-design-lite {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -234px; } }
.materialize-css {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -234px; } }
.mathjax {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -234px; } }
.maxcdn {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -234px; } }
.maxsite-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -234px; } }
.mean-io {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -234px; } }
.mediaelement-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -234px; } }
.mediatomb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -234px; } }
.mediawiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -234px; } }
.medium {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -234px; } }
.meebo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -234px; } }
.meteor {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -234px; } }
.methode {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -234px; } }
.microsoft-asp-net {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -234px; } }
.microsoft-excel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -234px; } }
.microsoft-powerpoint {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -234px; } }
.microsoft-publisher {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -234px; } }
.microsoft-sharepoint {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -234px; } }
.microsoft-word {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -234px; } }
.microsoft {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -234px; } }
.milligram {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -234px; } }
.minibb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -234px; } }
.mint {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -234px; } }
.mithril {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -234px; } }
.mixpanel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -234px; } }
.mobify {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -234px; } }
.mochikit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -234px; } }
.modernizr {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -234px; } }
.moguta-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -234px; } }
.moinmoin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -234px; } }
.mojolicious {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -234px; } }
.mollom {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -260px; } }
.moment-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -260px; } }
.mondo-media {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -260px; } }
.mongodb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -260px; } }
.mongrel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -260px; } }
.monkey-http-server {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -260px; } }
.mono-net {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -260px; } }
.mono {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -260px; } }
.mootools {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -260px; } }
.moodle {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -260px; } }
.motocms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -260px; } }
.movable-type {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -260px; } }
.mozard-suite {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -260px; } }
.mura-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -260px; } }
.mustache {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -260px; } }
.mybb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -260px; } }
.mybloglog {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -260px; } }
.mysql {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -260px; } }
.mynetcap {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -260px; } }
.nvd3 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -260px; } }
.navegg {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -260px; } }
.neos {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -260px; } }
.nepso {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -260px; } }
.netlify {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -260px; } }
.neto {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -260px; } }
.netsuite {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -260px; } }
.nette-framework {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -260px; } }
.new-relic {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -260px; } }
.nextgen-gallery {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -260px; } }
.nginx {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -260px; } }
.nodebb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -260px; } }
.nuxt-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -260px; } }
.owl-carousel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -260px; } }
.oxid-eshop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -260px; } }
.october-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -260px; } }
.odoo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -260px; } }
.olark {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -260px; } }
.oneapm {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -260px; } }
.onestat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -260px; } }
.open-adstream {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -260px; } }
.open-classifieds {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -260px; } }
.open-journal-systems {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -260px; } }
.open-web-analytics {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -286px; } }
.open-eshop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -286px; } }
.opencart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -286px; } }
.opencms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -286px; } }
.opengrok {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -286px; } }
.openlayers {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -286px; } }
.opennemas {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -286px; } }
.openresty {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -286px; } }
.openssl {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -286px; } }
.opentext-web-solutions {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -286px; } }
.openui5 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -286px; } }
.ophal {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -286px; } }
.optimizely {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -286px; } }
.oracle {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -286px; } }
.orchard-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -286px; } }
.outbrain {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -286px; } }
.outlook {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -286px; } }
.pansite {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -286px; } }
.pdf-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -286px; } }
.php-fusion {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -286px; } }
.php-nuke {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -286px; } }
.php {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -286px; } }
.pagekit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -286px; } }
.pagevamp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -286px; } }
.parcel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -286px; } }
.pardot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -286px; } }
.parse-ly {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -286px; } }
.paypal {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -286px; } }
.pencilblue {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -286px; } }
.pendo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -286px; } }
.percussion {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -286px; } }
.perl {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -286px; } }
.phabricator {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -286px; } }
.phaser {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -286px; } }
.phenomic {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -286px; } }
.phusion-passenger {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -286px; } }
.pinterest {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -286px; } }
.piwik {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -286px; } }
.planet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -286px; } }
.plataforma-neo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -286px; } }
.platformos {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -286px; } }
.play {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -286px; } }
.plentymarkets {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -312px; } }
.plesk {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -312px; } }
.pligg {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -312px; } }
.plone {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -312px; } }
.plotly {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -312px; } }
.po-st {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -312px; } }
.polymer {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -312px; } }
.posterous {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -312px; } }
.postgresql {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -312px; } }
.powergap {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -312px; } }
.prebid {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -312px; } }
.prefix-free {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -312px; } }
.prestashop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -312px; } }
.prism {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -312px; } }
.project-wonderful {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -312px; } }
.projectpoi {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -312px; } }
.prototype {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -312px; } }
.proximis-omnichannel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -312px; } }
.pubmatic {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -312px; } }
.public-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -312px; } }
.pure-css {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -312px; } }
.pyrocms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -312px; } }
.python {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -312px; } }
.quantcast {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -312px; } }
.quick-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -312px; } }
.quick-cart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -312px; } }
.quill {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -312px; } }
.rbs-change {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -312px; } }
.rcms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -312px; } }
.rd-station {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -312px; } }
.rdoc {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -312px; } }
.rackcache {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -312px; } }
.rainloop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -312px; } }
.rakuten-dbcore {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -312px; } }
.rakutendigitalcommerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -312px; } }
.ramda {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -312px; } }
.raphael {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -312px; } }
.raspbian {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -312px; } }
.rayo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -312px; } }
.react {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -312px; } }
.red-hat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -312px; } }
.reddit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -312px; } }
.redmine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -338px; } }
.reinvigorate {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -338px; } }
.requirejs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -338px; } }
.resin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -338px; } }
.reveal-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -338px; } }
.revel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -338px; } }
.rightjs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -338px; } }
.riot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -338px; } }
.ritecms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -338px; } }
.roadiz-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -338px; } }
.robin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -338px; } }
.rockrms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -338px; } }
.rocket {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -338px; } }
.roundcube {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -338px; } }
.rubicon-project {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -338px; } }
.ruby-on-rails {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -338px; } }
.ruby {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -338px; } }
.ruxit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -338px; } }
.rxjs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -338px; } }
.s-builder {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -338px; } }
.sap {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -338px; } }
.sdl-tridion {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -338px; } }
.simsite {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -338px; } }
.smf {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -338px; } }
.sobi-2 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -338px; } }
.spdy {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -338px; } }
.sql-buddy {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -338px; } }
.sqlite {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -338px; } }
.suse {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -338px; } }
.swfobject {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -338px; } }
.saia-pcd {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -338px; } }
.sails-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -338px; } }
.salesforce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -338px; } }
.sarka-spip {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -338px; } }
.sazito {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -338px; } }
.scala {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -338px; } }
.scholica {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -338px; } }
.scientific-linux {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -338px; } }
.seamlesscms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -338px; } }
.segment {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -338px; } }
.select2 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -338px; } }
.semantic-ui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -338px; } }
.sencha-touch {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -364px; } }
.sentry {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -364px; } }
.serendipity {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -364px; } }
.shadow {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -364px; } }
.shapecss {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -364px; } }
.sharethis {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -364px; } }
.shellinabox {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -364px; } }
.shinystat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -364px; } }
.shopatron {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -364px; } }
.shopcada {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -364px; } }
.shoper {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -364px; } }
.shoperfa {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -364px; } }
.shopery {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -364px; } }
.shopfa {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -364px; } }
.shopify {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -364px; } }
.shoptet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -364px; } }
.shopware {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -364px; } }
.silva {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -364px; } }
.silverstripe {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -364px; } }
.simplebo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -364px; } }
.site-meter {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -364px; } }
.sitecatalyst {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -364px; } }
.siteedit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -364px; } }
.sitecore {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -364px; } }
.sitefinity {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -364px; } }
.sivuviidakko {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -364px; } }
.sizmek {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -364px; } }
.slimbox-2 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -364px; } }
.slimbox {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -364px; } }
.smart-ad-server {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -364px; } }
.smartsite {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -364px; } }
.smartstore {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -364px; } }
.snap {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -364px; } }
.snap-svg {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -364px; } }
.snoobi {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -364px; } }
.sobipro {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -364px; } }
.socket-io {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -364px; } }
.solodev {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -364px; } }
.solr {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -364px; } }
.solusquare {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -364px; } }
.solve-media {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -364px; } }
.soundmanager {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -364px; } }
.sphinx {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -390px; } }
.spidercontrol-ininet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -390px; } }
.spincms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -390px; } }
.splunk {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -390px; } }
.spree {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -390px; } }
.sqreen {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -390px; } }
.squarespace {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -390px; } }
.squirrelmail {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -390px; } }
.squiz-matrix {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -390px; } }
.stackla {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -390px; } }
.starlet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -390px; } }
.statcounter {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -390px; } }
.store-systems {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -390px; } }
.strapi {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -390px; } }
.stripe {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -390px; } }
.sublimevideo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -390px; } }
.subrion {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -390px; } }
.sulu {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -390px; } }
.sumome {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -390px; } }
.supersized {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -390px; } }
.sweetalert {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -390px; } }
.sweetalert2 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -390px; } }
.swiftlet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -390px; } }
.symfony {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -390px; } }
.synology-diskstation {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -390px; } }
.syntaxhighlighter {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -390px; } }
.twiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -390px; } }
.typo3 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -390px; } }
.taiga {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -390px; } }
.tawkto {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -390px; } }
.tealeaf {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -390px; } }
.tealium {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -390px; } }
.teamcity {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -390px; } }
.telescope {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -390px; } }
.tengine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -390px; } }
.textpattern-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -390px; } }
.thelia {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -390px; } }
.thinkphp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -390px; } }
.ticimax {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -390px; } }
.tiddlywiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -390px; } }
.tiki-wiki-cms-groupware {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -390px; } }
.tilda {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -390px; } }
.timeplot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -416px; } }
.tinymce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -416px; } }
.titan {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -416px; } }
.tomatocart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -416px; } }
.tornadoserver {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -416px; } }
.totalcode {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -416px; } }
.trac {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -416px; } }
.trackjs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -416px; } }
.tumblr {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -416px; } }
.tweenmax {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -416px; } }
.twilight-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -416px; } }
.twistphp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -416px; } }
.twistedweb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -416px; } }
.twitter-flight {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -416px; } }
.twitter-typeahead-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -416px; } }
.twitter {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -416px; } }
.typepad {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -416px; } }
.typekit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -416px; } }
.uikit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -416px; } }
.umi-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -416px; } }
.unix {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -416px; } }
.ubercart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -416px; } }
.ubuntu {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -416px; } }
.ultracart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -416px; } }
.umbraco {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -416px; } }
.unbounce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -416px; } }
.underscore-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -416px; } }
.usabilla {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -416px; } }
.userengage {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -416px; } }
.userguiding {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -416px; } }
.userlike {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -416px; } }
.userrules {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -416px; } }
.uservoice {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -416px; } }
.ushahidi {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -416px; } }
.vivvo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -416px; } }
.vp-asp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -416px; } }
.vtex-enterprise {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -416px; } }
.vtex-integrated-store {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -416px; } }
.vaadin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -416px; } }
.vanilla {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -416px; } }
.varnish {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -416px; } }
.venda {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -416px; } }
.veoxa {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -442px; } }
.videojs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -442px; } }
.viglink {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -442px; } }
.vignette {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -442px; } }
.vimeo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -442px; } }
.virgool {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -442px; } }
.virtuemart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -442px; } }
.visual-webgui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -442px; } }
.visualpath {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -442px; } }
.volusion {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -442px; } }
.vue-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -442px; } }
.w3-total-cache {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -442px; } }
.w3counter {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -442px; } }
.webxpay {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -442px; } }
.whmcs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -442px; } }
.wp-rocket {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -442px; } }
.wp-statistics {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -442px; } }
.warp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -442px; } }
.web2py {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -442px; } }
.webgui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -442px; } }
.webpublisher {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -442px; } }
.website-x5 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -442px; } }
.webix {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -442px; } }
.webs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -442px; } }
.websplanet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -442px; } }
.websale {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -442px; } }
.websitebaker {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -442px; } }
.websitecreator {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -442px; } }
.webtrekk {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -442px; } }
.webtrends {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -442px; } }
.weebly {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -442px; } }
.weglot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -442px; } }
.wikkawiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -442px; } }
.windowsserver {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -442px; } }
.wink {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -442px; } }
.wix {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -442px; } }
.wolf-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -442px; } }
.woltlab-community-framework {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -442px; } }
.woocommerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -442px; } }
.woopra {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -442px; } }
.woosa {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -442px; } }
.wordpress {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -442px; } }
.wowza-media-server {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -468px; } }
.x-cart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -468px; } }
.xampp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -468px; } }
.xmb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -468px; } }
.xoops {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -468px; } }
.xregexp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -468px; } }
.xajax {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -468px; } }
.xanario {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -468px; } }
.xenforo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -468px; } }
.xitami {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -468px; } }
.xpressengine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -468px; } }
.yui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -468px; } }
.yabb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -468px; } }
.yandex-direct {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -468px; } }
.yandex-metrika {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -468px; } }
.yaws {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -468px; } }
.yii {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -468px; } }
.yoast-seo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -468px; } }
.youtrack {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -468px; } }
.youtube {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -468px; } }
.zk {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -468px; } }
.zurb-foundation {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -468px; } }
.zabbix {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -468px; } }
.zanox {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -468px; } }
.zen-cart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -468px; } }
.zend {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -468px; } }
.zendesk-chat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -468px; } }
.zenfolio {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -468px; } }
.zepto {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -468px; } }
.zeuscart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -468px; } }
.zinnia {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -468px; } }
.zope {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -468px; } }
.a-blog-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -468px; } }
.acquia-cloud {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -468px; } }
.actionhero-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -468px; } }
.adminer {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -468px; } }
.adnegah {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -468px; } }
.adobedmt {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -468px; } }
.after-buy {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -468px; } }
.aircall {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -468px; } }
.akamai {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -468px; } }
.akaunting {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -468px; } }
.akka-http {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -494px; } }
.all-in-one-seo-pack {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -494px; } }
.amcharts {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -494px; } }
.amber {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -494px; } }
.amplitude {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -494px; } }
.apostrophecms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -494px; } }
.arcgis-icon {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -494px; } }
.asciinema {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -494px; } }
.aws-ec2 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -494px; } }
.aws-elb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -494px; } }
.aws-s3 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -494px; } }
.aws {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -494px; } }
.bablic {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -494px; } }
.basket-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -494px; } }
.bizweb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -494px; } }
.bubble {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -494px; } }
.byinti {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -494px; } }
.cpanel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -494px; } }
.caddy {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -494px; } }
.cgit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -494px; } }
.chevereto {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -494px; } }
.cloudcart {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -494px; } }
.coaster-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -494px; } }
.coinhave {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -494px; } }
.coinimp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -494px; } }
.coinlab {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -494px; } }
.colormeshop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -494px; } }
.comscore {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -494px; } }
.datadome {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -494px; } }
.debut {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -494px; } }
.deepminer {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -494px; } }
.default {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -494px; } }
.docusaurus {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -494px; } }
.e107 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -494px; } }
.esyndicat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -494px; } }
.ez-publish {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -494px; } }
.ebis {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -494px; } }
.ec-cube {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -494px; } }
.ef-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -494px; } }
.enduro-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -494px; } }
.ensighten {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -494px; } }
.epages {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -494px; } }
.etherpad {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -520px; } }
.flarum {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -520px; } }
.foswiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -520px; } }
.freshchat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -520px; } }
.freshmarketer {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -520px; } }
.futureshop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -520px; } }
.gerrit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -520px; } }
.git {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -520px; } }
.gitea {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -520px; } }
.gogs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -520px; } }
.google-cloud {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -520px; } }
.gosquared {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -520px; } }
.govcms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -520px; } }
.gravityforms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -520px; } }
.gunicorn {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -520px; } }
.hapi-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -520px; } }
.hinza-advanced-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -520px; } }
.http2 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -520px; } }
.ipresta {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -520px; } }
.iweb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -520px; } }
.idosellshop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -520px; } }
.ikiwiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -520px; } }
.imperiacms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -520px; } }
.infusionsoft {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -520px; } }
.inspectlet {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -520px; } }
.inwemo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -520px; } }
.io4-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -520px; } }
.ionic {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -520px; } }
.iplabel {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -520px; } }
.jqtouch {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -520px; } }
.jquery-mobile {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -520px; } }
.jquery-ui {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -520px; } }
.jquery {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -520px; } }
.jimdo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -520px; } }
.jqplot {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -520px; } }
.kemalcr {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -520px; } }
.kibana {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -520px; } }
.koha {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -520px; } }
.laterpay {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -520px; } }
.libwww-perl-daemon {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -520px; } }
.lighttpd {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -520px; } }
.mailchimp {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -520px; } }
.marked {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -546px; } }
.math-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -546px; } }
.mattermost {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -546px; } }
.mautic {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -546px; } }
.meliscmsv2 {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -546px; } }
.mhonarc {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -546px; } }
.mietshop {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -546px; } }
.mini-httpd {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -546px; } }
.mkdocs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -546px; } }
.mobirise {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -546px; } }
.mod-perl {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -546px; } }
.mod-python {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -546px; } }
.mod-ssl {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -546px; } }
.mod-wsgi {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -546px; } }
.modified {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -546px; } }
.monerominer {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -546px; } }
.moon {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -546px; } }
.mouseflow {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -546px; } }
.mypage-platform {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -546px; } }
.node-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -546px; } }
.nopcommerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -546px; } }
.octopress {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -546px; } }
.openengine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -546px; } }
.oscss {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -546px; } }
.oscommerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -546px; } }
.osticket {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -546px; } }
.otrs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -546px; } }
.owncloud {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -546px; } }
.papaya-cms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -546px; } }
.paperjs {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -546px; } }
.parselecom {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -546px; } }
.pelican {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -546px; } }
.phpalbum {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -546px; } }
.phpbb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -546px; } }
.phpcms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -546px; } }
.phpdocumentor {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -546px; } }
.phpmyadmin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -546px; } }
.phppgadmin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -546px; } }
.phpsqlitecms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -546px; } }
.phpdebugbar {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -546px; } }
.phpliteadmin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -546px; } }
.phpwind {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -546px; } }
.pimcore {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -572px; } }
.pirobasecms {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -572px; } }
.polyfill {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -572px; } }
.prettyphoto {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -572px; } }
.projesoft {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -572px; } }
.punbb {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -572px; } }
.pygments {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -572px; } }
.question2answer {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -572px; } }
.raychat {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -572px; } }
.recaptcha {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -572px; } }
.redoc {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -572px; } }
.revslider {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -572px; } }
.sifr {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -572px; } }
.snews {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -572px; } }
.script-aculo-us {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -572px; } }
.scrollreveal {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -572px; } }
.shopline {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -572px; } }
.signal {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -572px; } }
.softtr {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -572px; } }
.sonar {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -572px; } }
.spip {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -572px; } }
.storeden {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -556px -572px; } }
.storyblok {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -582px -572px; } }
.strapdown-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -608px -572px; } }
.strato {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -634px -572px; } }
.styled-components {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -660px -572px; } }
.sucuri {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -686px -572px; } }
.svbtle {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -712px -572px; } }
.swift-engine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -738px -572px; } }
.swiftype {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -764px -572px; } }
.sympa {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -790px -572px; } }
.tailwindcss {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -816px -572px; } }
.textalk {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -842px -572px; } }
.three-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -868px -572px; } }
.thttpd {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -894px -572px; } }
.tictail {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -920px -572px; } }
.total-js {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -946px -572px; } }
.transifex {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -972px -572px; } }
.translucide {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -998px -572px; } }
.tray {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1024px -572px; } }
.typecho {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1050px -572px; } }
.ucore {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -1076px -572px; } }
.ucoz {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -10px -598px; } }
.uknowva {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -36px -598px; } }
.user-com {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -62px -598px; } }
.vbulletin {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -88px -598px; } }
.vibecommerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -114px -598px; } }
.vigbo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -140px -598px; } }
.vwo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -166px -598px; } }
.webedition {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -192px -598px; } }
.webdev {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -218px -598px; } }
.webmine {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -244px -598px; } }
.webpack {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -270px -598px; } }
.websocket {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -296px -598px; } }
.wikinggruppen {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -322px -598px; } }
.wpcache {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -348px -598px; } }
.wp-super-cache {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -374px -598px; } }
.xeora {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -400px -598px; } }
.xonic {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -426px -598px; } }
.xtcommerce {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -452px -598px; } }
.xwiki {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -478px -598px; } }
.yahoo {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -504px -598px; } }
.zeit {&::before { width: 16px; height: 16px; margin-right: 5px;  background-position: -530px -598px; } }

}