@import '../../../../variables.scss';
.lead-cont{
  border: 1px solid #eeeeee;
  background-color: $white;
  border-radius: 2px;
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  // min-width: 700px;
  cursor: pointer;
  &__list{
    display: flex;
    margin: 0 -10px;
    align-items: center;
  }
  &-item{
    padding: 0 10px;
    width: 1 / 5 * 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: space-between;
    &:last-child{
      //margin-left: auto;
      width: 20px;
      // flex-direction: row;
      // align-items: center;
      // justify-content: space-between;
    }
    &__title{
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 5px;
    }
    &__name{
      font-size: 10px;
      opacity: 0.7;
    }
    &__total{
      font-size: 12px;
      display: flex;
      align-items: center;
      span{
        display: block;
        padding: 3px 8px;
        margin-left: 5px;
        background-color: $orange-light;
        border-radius: 10px;
        color: #ec971d;
        font-size: 10px;
      }
    }
    &__date{
      font-size: 10px;
      opacity: 0.7;
    }
    &__status{
      color: $success;
      font-size: 12px;
      margin: 0 0 4px;
      background-color: $success-light;
      border-radius: 2px;
      display: inline-block;
    }
    &__day{
      @extend .lead-cont-item__date
    }
    &__leads{
      font-size: 12px;
      margin: 0 0 4px;
      opacity: $normal-opacity;
    }
    &__line{
      width: 58px;
      border-radius: 1px;
      height: 3px;
      background-color: #eeeeee;
      position: relative;
      div{
        position: absolute;
        width: 50%;
        height: 100%;
        border-radius: 1px;
        background-color: #ec971d;
        top: 0;
        left: 0;
      }
    }
    &__show{
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-weight: 600;
      color: #ec971d;
      font-size: 12px;
      cursor: pointer;
      white-space: nowrap;
    }
    &__icon{
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $success-light;
      margin-left: 20px;
      cursor: pointer;
    }
    &__dots{
      display: flex;
      flex-direction: column;
      position: relative;
      // margin-left: 50px;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      li {
        width: 3px;
        height: 0.2rem;
        background-color: $gray;
        border-radius: 50%;
        margin: 0 0 3px;
        &:last-child{
          margin: 0;
        }
      }
    }
  }
  /* three dots menu css */
  .menu {
    //width: 170px;
    display: flex;
    flex-direction: column;
  }
  .menu .popup-content {
    margin: 0;
    border: none !important;
    //margin-top: 100px;
    // width: 171px !important;
    //box-shadow: 2px 1px 3px -1px rgba(196, 184, 196, 0.2) !important;
    .popup-content {
      margin-top: -1px !important;
      border: 1px solid #bbbbbb99 !important;
    }
  }
  .popup-content {
    box-shadow: none !important;
    border-color: #eee !important;
    padding: 0 !important;
    border-radius: 2px !important;
    margin: 4px;
  }
  .menu-item {
    cursor: pointer;
    //padding: 10px 20px;
    border-bottom: 1px solid #eee;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-child {
      border-bottom: 0;
    }
  }
  .field-margin {
    margin-top: 30px
  }
  .check-margin {
    margin-top: 10px
  }
  .mv-20 {
    margin: 20px 0;
  }
  .mar-top{
    margin-top: 50px;
  }
  .input-margin{
    margin-top: 20px
  }
  .wide {
    max-width: 550px;
  }
  .orange-text {
    color: #ec971d;
    margin: 10px 0;
  }
  .sheet-block {
    display: flex;
    flex-wrap: wrap;
    .inner-sheet.sheet-left {
      width: 70%;
      padding-right: 50px;
    }
    .inner-sheet.sheet-right {
      width: 30%;
    }
  }

  .scroll_view  {
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 112px;
  }
  .light-text {
    font-size: 12px;
    opacity: 0.7;
    line-height: 18px;
    font-weight: 300;
  }
  &:last-child {
    .menu .popup-content {
      margin-top: 0 !important;
    }
  }
}

// .accbased__content {
//   min-height: calc(100vh - 81px);
// }
.icon-set {
  justify-content: flex-start !important;
  img {
    transform:rotate(0) !important;
    margin-right: 10px;
    width: 20px;
    padding-left: 3px;
    padding-right: 3px;
    &.woodpecker {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.no-border{
  border: none
}
.lists-capacity img {
  opacity: 0.3;
}
.red-font{
  color: $danger
}

// popup-cont

.popup-cont{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
    p.add-list__title {
      margin-bottom: 10px !important;
      text-transform: uppercase;
      text-align: center
    }
    p.add-list__create {
      text-transform: inherit;
      font-weight: 300;
      font-size: 12px;
    }
    img{
      background-color: $danger;
      height: 20px;
      width: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
}

//Popup-cont-error
.popup-cont-error{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  border: 1px solid $danger;
    p.add-list__title {
      margin-bottom: 10px !important;
      color: $danger;
      text-transform: uppercase;
      margin-top: 18px;
      font-size: 12px;
      font-weight: 600;
    }
    .del-icon {
      height: 20px;
      width: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
    span{
      text-decoration: underline;
      text-decoration-color: $danger ;
    }
}
// accbased__content-error

.accbased__content-error {
  position: relative;

  .alert-div {
    position: fixed;
    top: 10px;
    right: 42px;
    padding: 25px 35px 25px 25px;
    background-color: $gray-light;
    border: 0px;
    z-index: 1111;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);

    .add-list__close {
      top: 30px;
      right: 25px;
    }
    p{
      margin-right: 26px;
    }
  }
}
