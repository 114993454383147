.confirmation-popup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  bottom: 0;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 55;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  overflow-y: auto;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &__content {
    background-color: #ffffff;
    padding: 40px;
    max-width: 410px;
    width: 100%;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 7px;
    height: 7px;
  }

  &__title {
    color: #ec971d;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 30px;
  }

  &__message {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 25px 0 10px;
    text-transform: uppercase;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  &__btn {
    display: inline-block;
    border: 1px solid transparent;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 20px;
    background-color: #ec971d;
    text-align: center;
    line-height: 22px;
    padding: 8px 30px 8px;
    cursor: pointer;

    &.orange-light {
      background-color: #fdf4e8;
      color: #ec971d;
      margin-left: 20px;
    }
  }
}