@import "./variables.scss";
@import "./technology.scss";
// @import "./test.scss";

* {
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: none;
  }
}

textarea {
  font-family: $font;
}

body {
  min-width: 320px;
  font-size: 14px;
  color: $dark-gray;
  font-family: $font;
}

.m-Style1{
  margin-top: 5px;
}

/*
End
*/
ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input,
select,
option,
textarea {
  appearance: none;
  font-size: 14px;
  color: #555555;
}

input,
select,
textarea {
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  padding: 0 15px 10px 0px;
  padding-top: 10px;
  &:focus {
    border-bottom: 1px solid #ec971d;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    font-size: 14px;
    color: $dark-gray;
    opacity: 0.6;
  }
}
// .loader-bg {
//   background-color: rgba(255, 255, 255, 0.1) !important;
// }
textarea {
  resize: none;
}

.accbased {
  min-height: 100vh;
  display: flex;
  &__right {
    flex-grow: 1;
    padding-top: 80px;
  }
  &__content {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    //overflow: hidden;

    // &.expanded {
    //   overflow: unset;
    // }

    .page-content {
      width: 100%;
    }
  }
  &__table {
    border: 1px solid #eeeeee;
    background-color: $white;
    border-radius: 2px;
    flex-grow: 1;
    margin-right: 40px;
  }
}

.switch {
  width: 34px;
  height: 14px;
  background-color: rgba(51, 51, 51, 0.3);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: all 300ms ease;
  span {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(0);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
    background-color: $gray-light;
    left: -3px;
    transition: all 300ms ease;
  }
  &.active {
    background-color: rgba(236, 151, 29, 0.3);
    span {
      left: 100%;
      transform: translateY(-50%) translateX(-95%);
      background-color: $orange;
    }
  }
  &__name {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 10px;
    letter-spacing: 0.3px;
  }
}
.check {
  width: 12px;
  height: 12px;
  border: 1px solid $gray;
  border-radius: 1px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &.active {
    border: none;
    background-image: url("/assest/accountBased/check.svg");

  }
  &.disabled {
    cursor: not-allowed;
  }
}

.loader-bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.73) !important;
  z-index: 19;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
  }
}

.success-div-loader {
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  background-color: rgba(255, 255, 255, 0.73);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
  }
}
.signin-loader {
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 0vh;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 555;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  img {
    width: 70px;
  }
}

.error-div {
  display: flex;
  border: 1px solid $danger;
  padding: 10px 20px;
  align-items: center;
  margin: 20px 0;
  font-size: 12px;
  p {
    color: $danger;
  }
  span {
    display: inline-block;
    padding: 5px;
    color: $danger;
  }
}
.error-div-ignoreList {
  display: flex;
  padding: 10px;
  align-items: center;
  margin: 20px 45px;
  p {
    color: $danger;
  }
  span {
    display: inline-block;
    padding: 5px;
    color: $danger;
  }
}
.success-div-logout {
  display: flex;
  border: 1px solid $success;
  padding: 10px 20px;
  align-items: center;
  margin: 20px 0;
  font-size: 12px;
  p {
    color: $success;
  }
  span {
    display: inline-block;
    padding: 5px;
    color: $danger;
  }
}
.success-div {
  display: flex;
  border-bottom: 3px solid $success;
  padding: 10px;
  align-items: center;
  margin: 50px 0;
  span {
    display: inline-block;
    padding: 5px;
    color: $success;
  }
}

.key-inner {
  width: 125px;
  padding-left: 0;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333 !important;
    font-size: 12px;
    text-transform: inherit;
  }
  img {
    background-color: #f3f3f3;
  }
}
.btn-align {
  display: flex;
  justify-content: space-between;
}

.error-red {
  color: $danger;
  position: absolute;
  left: 0;
  font-size: 12px;
  bottom: -19px;
}
//Toggel Between Select In View And All drop Down
.dropdown-start {
  border: 1px solid #eeeeee;
  border-radius: 2px 2px 0 0;
  box-shadow: 1px 2px 5px $gray-light;
  position: absolute;
  z-index: 2;
  background-color: $white;
  left: 20px;
  top: 15px;
  min-width: 135px;
  white-space: nowrap;
  .dropdown-in-group {
    justify-content: flex-start;
  }
  .check {
    margin-top: 1px;
  }
}
.ignore-header__check {
  position: relative;
}
.dropdown-in-ignoreName {
  margin-left: 7px;
  font-size: 12px;
  font-weight: 500;
}

// Pagination Drop Down
.pagination-block {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.dropdown-in-group {
  padding: 10px 15px;
}
.pagination-box {
  display: flex;
  border: 1px solid #eee;
  position: relative;
  cursor: pointer;
}
.pagination-box .dropdown-in__groups {
  max-height: inherit;
  overflow: inherit;
}

.pagination-box span {
  opacity: 0.4;
  display: inline-block;
  height: 20px;
  width: 25px;
  text-align: center;
  font-size: 12px;
  padding-top: 2px;
}
.pagination-items {
  display: flex;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  background-color: #fff;
  margin: 0;
  align-items: center;
  position: absolute;
  min-width: 28px;
  bottom: 22px;
  left: -2px;
  right: -2px;
}
.pagination-item {
  opacity: 0.4;
}

.dropdown-in, .dropdown-in__groups, .tags-group, .field-role__tags, .field__exclude, .slim-scroll, .tech-autosizer {
  &::-webkit-scrollbar-track {
    background-color: #eee;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ec971d;
    border: 2px solid #ec971d;
    border-radius: 1px;
  }
}

.tech-autosizer {
  div.dropdown-in-group {
    height: 36px;
  }

  div.dropdown-in-group > p:nth-child(1) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div.dropdown-in-group.selected {
    background-color: $orange-light !important;
  }
}

//styling for Download Button in IgnoreList
.download-icon {
  text-align: center;
  margin: 20px;
  cursor: pointer;
  p {
    color: #ec971d;
    font-size: 10px;
    font-weight: 200;

    img {
      margin-right: 5px;
    }
  }
}
// input error
.errorInput-red {
  color: $danger;
  font-size: 12px;
  bottom: -20px;
}

//disable button
.disable-btn {
  pointer-events: none;
  opacity: 0.5;
}

//Cursor Pointer
.on-pointer {
  cursor: pointer;
}

//Try Again Click

.text-try-again {
  cursor: pointer;
  text-decoration: underline;
}

// calendar Design
.cal-body {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  font-family: $font;
  position: absolute;
  right: 0;
  z-index: 2;
  display: flex;
  top: 53px;
  height: auto;
  .side-c {
    float: left;
    width: 200px;
    background-color: #fff;
    border: 1px solid #eeeeee;
    border-right: 0px;
  }
  .date-range {
    float: left;
    width: 100%;
  }
  .date-range h3 {
    font-size: 12px;
    color: #333;
    font-weight: 500;
  }
  .days-range {
    width: 100%;
  }
  .list {
    list-style: none;
    padding: 0;
    li {
      font-size: 12px;
      color: #5a5a5a;
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;

      &:hover {
        background-color: $orange-light;
      }
      &:first-child {
        padding: 15px 10px;
        height: 44px;
      }
      &:first-child:hover {
        background-color: transparent;
      }
    }
  }
  .dates {
    display: flex;
  }
  .input-field {
    float: left;
    width: 50%;
    padding: 0 10px;
    margin-top: 10px;
  }
  .input-field label {
    font-size: 12px;
    color: #c7c7c7;
  }
  .input-field input {
    border: 0px;
    background-color: transparent;
    color: #353535;
    margin-top: 3px;
    border-bottom: 1px solid #eeeeee;
    display: block;
    width: 100%;
    padding: 5px 0;
  }
  .react-calendar button {
    text-transform: uppercase;
    font-size: 12px;
  }
  .react-calendar__navigation__label {
    color: #333;
    font-weight: 500;
  }
  .react-calendar__month-view__days button {
    margin-bottom: 2px;
  }
  .react-calendar__tile {
    padding: 1em 0.5em;
  }

  .react-calendar {
    width: 290px;
    border: 1px solid #eeeeee;
    .react-calendar__viewContainer {
      padding: 0 1em 1em 1em;
    }

    &__tile--rangeStart {
      background: #ec971d !important;
      color: #fff !important;
      border-radius: 4px 0 0 4px;
    }
    &__tile--rangeEnd {
      background: #ec971d !important;
      color: #fff !important;
      border-radius: 0 4px 4px 0;
    }
    &__tile--active {
      background-color: $orange-light;
      &:enabled:hover,
      &:enabled:focus {
        background-color: #ec971d;
        color: #fff;
      }
    }
    &__tile--rangeBothEnds {
      border-radius: 0;
    }
    &__month-view {
      &__days {
        &__day {
          color: #3f3e3e;
          &--neighboringMonth {
            color: #dfdfdf;
          }
          // &--weekend{
          // color: #3f3e3e;
          // }
        }
      }
      &__weekdays {
        &__weekday {
          display: none;
        }
      }
    }
    &__navigation {
      border-bottom: 1px solid #eeeeee;

      &__next2-button {
        display: none;
      }
      &__prev2-button {
        display: none;
      }
    }
  }
}

// ============== Tooltip-Design =================

.__react_component_tooltip.type-dark {
  background-color: #ec971d;
  opacity: 1;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.3px;
  padding: 7px;
  color: #fff;
  max-width: 150px;
  word-break: break-word;
}
.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: #ec971d;
}
.__react_component_tooltip.type-dark.place-left:after {
  border-left-color: #ec971d;
}
.__react_component_tooltip.type-dark.place-bottom:after {
  border-bottom-color: #ec971d;
}
.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: #ec971d;
}
// Tost=======================
.Toastify__toast-container--top-right {
  top: 6.5em;
  right: 1.7em;
}
.Toastify__toast-container {
  padding: 16px;
  width: 300px;
}
.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--default {
  font-size: 12px;
  color: $gray;
  font-family: $font;
  background-color: #fff;
}
.Toastify__progress-bar {
  height: 3px;
  bottom: -3px;
}
.Toastify__toast {
  padding: 16px;
  min-height: 62px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1), 0 5px 12px 0 rgba(0, 0, 0, 0.05);
}
.Toastify__close-button {
  position: absolute;
  top: 27px;
  right: 15px;
  width: 6px;
  height: 6px;
  background: url(/assest/accountBased/close.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  text-indent: -9999px;
  opacity: 1;
}
.Toastify__progress-bar--default {
  background: #ec971d;
}
.toast-success-div {
  border-bottom: 3px solid $success;
}

.Toastify__toast-body {
  direction: ltr;
}
.toast-success-div .Toastify__toast-body:before {
  content: "";
  width: 10px;
  height: 10px;
  background: url(/assest/apiKeys/check-symbol.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
  float: left;
  position: relative;
  top: 3px;
}

.toast-error-div {
  border-bottom: 3px solid $danger !important;
}
.Toastify__toast-body {
  text-align: left;
}
.toast-error-div .Toastify__toast-body:before {
  content: "";
  width: 8px;
  height: 8px;
  background: url(/assest/accountBased/close.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 3px;
  float: left;
}

.Toastify__toast {
  border-bottom: 3px solid #ec971d;
  overflow: inherit;
}
.Toastify__progress-bar--default {
  background: #fff !important;
}
.Toastify__toast--success {
  border-color: $success;
}
.toast-error-div .Toastify__progress-bar,
.Toastify__toast--success .Toastify__progress-bar {
  opacity: 0 !important;
}
.mes-done .Toastify__progress-bar--default {
  background: transparent !important;
}

button.MuiButton{
&-root{
  font-family: $font !important;
&:hover{
  background-color: rgba(0, 0, 0, 0.0) !important;
}
}
}

button.MuiFab {
  &-primary {
    color: $white;
    font-family: $font;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500 !important;
    padding: 10px 30px !important;
    background-color: $orange;
    white-space: nowrap;
    box-shadow: none !important;
    text-transform: capitalize;
    &:hover {
      background-color: #e38c0f;
    }
    &:focus,
    &:active {
      background-color: $pressed-primary-btn;
    }
  }
  &-secondary {
    border: 1px solid transparent;
    color: $orange;
    font-family: $font;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500 !important;
    padding: 10px 30px !important;
    background-color: $orange-light;
    white-space: nowrap;
    box-shadow: none !important;
    text-transform: capitalize;
    &:hover {
      background-color: $orange-light;
      border: 1px solid $orange;
    }
    &:focus,
    &:active {
      background-color: $pressed-secondary-btn;
    }
  }
}
input:focus,
select:focus,
textarea:focus {
  border-color: #ec971d !important;
  background: #fffaf0;
}

//Google Autofill Input Background to transparent
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #555555 !important;
}
.scroll_view::-webkit-scrollbar-track {
  background-color: #eee;
}
.scroll_view::-webkit-scrollbar {
  width: 3px;
  background-color: #eee;
}
.scroll_view::-webkit-scrollbar-thumb {
  background-color: #ec971d;
  border: 2px solid #ec971d;
  border-radius: 1px;
}
.ml-5 {
  margin-left: 5px;
}

.mt-0 {
  margin-top: 0;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.menu-btn {
  opacity: 0.6;
  display: none;
  cursor: pointer;
  img {
    width: 16px;
  }
  &:hover {
    opacity: 0.9;
  }
}
.markbased__table {
  // overflow-x: auto;
  margin-right: 0;
}
// .ignore-header {
//   border-bottom: 0;
// }

// Tab&Mobile media
@media (min-width: 1024px) {
  .grid-left {
    flex-grow: 1 !important;
    max-width: inherit !important;
  }
  .grid-right {
    max-width: 306px !important;
    // overflow: hidden;
  }
  .loader-bg {
    left: 215px;
    img {
      margin-right: 215px;
    }
  }
}
@media (min-width: 768px) {
  .scroll-view {
    width: inherit !important;
  }
  .coupon-filter {
    min-height: 500px;
  }
  .sidebar-filter {
    margin-top: 70px;
  }
}
@media (max-width: 1024px) {
  .pass-cont {
    &__text {
      position: static;
      margin-top: 10px;
    }
  }
  .sign-in__text {
    position: static;
    margin-top: 10px;
  }
  .sign-in {
    padding: 20px;
  }
  .right-banner__img {
    display: flex;
    align-items: flex-end;
  }
  .right-banner__img img {
    width: 15px;
  }
}
@media (max-width: 959px) {
  .lists__rightbar {
    margin-left: 0;
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .coupon {
    min-width: 600px;
  }
  .green {
    margin: 10px;
  }
  .menu-btn {
    display: inline-block;
  }
  .sidebar,
  .sidebar-fixed {
    position: fixed;
    left: -215px;
    z-index: 555;
    -moz-transition: 0.3s linear;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s linear;
    height: 100%;
  }
  .sidebar-fixed .bg-gray {
    position: fixed;
    cursor: pointer;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.33);
    width: auto;
    top: 0;
    left: 0;
  }
  .left-menu .sidebar,
  .left-menu .sidebar-fixed {
    left: 0;
  }
  .left-menu.sidebar-fixed .bg-gray {
    opacity: 1;
    visibility: visible;
  }
  .nav-search {
    left: 0;
  }
  .nav-search {
    padding: 15px;
  }
  .nav-search-person {
    display: flex;
    width: 54px;
    justify-content: flex-end;
    margin-left: 0px;
  }
  .nav-search-person__icon {
    display: none;
  }
  .accbased__content {
    padding: 10px;
  }
  .nav-search__box {
    flex-grow: inherit;
    width: 73%;
  }
  .nav-search__inp {
    margin-left: 10px;
    max-width: 45%;
  }
  .cal-body {
    top: inherit;
    bottom: 0;
  }
}
@media (max-width: 768px) {
  .ignore-add__row {
    flex-direction: column;
    .uploads,
    .ignore-add__col {
      width: 100%;
    }
    .uploads {
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 480px) {
  .pass-cont {
    min-height: 620px;
    justify-content: space-between;
    padding-bottom: 40px;
  }
  .nav-search__inp {
    max-width: 100px;
  }
  .nav-search-lead {
    width: auto;
  }
  .integration-img {
    height: 21px;
  }
  // .nav-search-lead {
  //   display: none;
  // }
}
@media (max-width: 360px) {
  .nav-search__inp {
    max-width: 100px;
  }
}

.scroll-view {
  width: 100%;
}
.sign-in > .signin-loader {
  img {
    display: none;
  }
}
.sign-in {
  form {
    position: relative;
    .signin-loader {
      bottom: 33%;
      background-color: transparent;
    }
  }
}

.pass-cont > .signin-loader {
  img {
    display: none;
  }
}
.pass-cont {
  form {
    position: relative;
    .signin-loader {
      bottom: 33%;
      background-color: transparent;
    }
  }
}
.input-error:focus,
.input-error {
  border-bottom: 1px solid $danger !important;
}

//Button Fadein Fadeout Animation
.fadeOut {
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
.markbased_profile_table {
  overflow: inherit !important;
  .profile-info__contacts.email {
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      overflow: inherit;
    }
  }
}

.people-market-field {
  z-index: 1;
}

.animation-slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}
@keyframes slideDown {
  0% {
    transform: translateY(-12%);
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-12%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}

.lists_table_loader {
  .loader-bg {
    position: static;
    min-height: 400px;
    img {
      margin-right: 0;
    }
  }
}
.sign-in__cont.lists_table_loader {
  position: relative;
  .loader-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-height: 100px;
    img {
      max-width: 60px;
      margin-bottom: 55px;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-unset {
  cursor: unset !important;
}

.cursor-notAllowed {
  cursor: not-allowed !important;
}

.position-relative {
  position: relative !important;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.content {
  &-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &-col-1 {
    width: 80%;
  }

  &-col-2 {
    width: 250px;
    min-width: 250px;
  }
}

@media (max-width: 991px) {
  .content {
    &-row {
      flex-direction: column;
    }

    &-col-1 {
      width: 100%;
    }

    &-col-2 {
      width: 100%;
    }
  }
}

.error-field {
  border-bottom: 1px solid #E01817 !important;
}
.error {
  color: #E01817;
  font-size: 10px;
  line-height: 13px;
}

input:disabled {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
}

button:disabled {
  cursor: not-allowed !important;
}


.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .input-group-append {
    display: flex;
  }

  input {
    border: 1px solid #ddd;
    padding: 8px;
    width: 75%;
    max-width: 250px;
    border-radius: 2px 0 0 2px;

    &::placeholder {
      font-size: 12px;
    }
  }

  button {
    font-family: "IBM Plex Sans", sans-serif;
    background-color: #e38c0f;
    text-align: center;
    border: 1px solid transparent;
    padding: .5rem 1.25rem;
    font-size: 12px;
    font-weight: 500;
    border-radius: 0 3px 3px 0;
    color: #fff;
    cursor: pointer;

    &:disabled {
      opacity: 0.6;
    }
  }
}

@media (max-width: 767px) {
  .coupon {
    min-width: 100%;
  }
}

:root {
  --orange: #{$orange};
}
