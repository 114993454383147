@import './imports/display';

.billing-user-list {
  background: url(/assest/inside/BG.png) no-repeat center;
  background-size: 100% 100%;
  padding: 10px 7px 10px;
}

@media (max-width: 991px) {
  .billing-stat {
    padding: 0 15px;
  }

  .billing-user-list {
    right: 15px;
  }
}

@media (max-width: 767px) {
  .market__box {
    padding-top: 20px;
  }

  .market__container {
    padding: 20px;
  }

  .market-header__top {
    padding: 12px 20px;
  }

  .based-header__top {
    padding: 12px 20px;
  }

  .people-market {
    .people-market__col {
      width: 100%;
      margin-bottom: 35px;

      &:nth-child(n+3) {
          margin-top: 0px;
      }
    }
  }

  .adv-lead {
    .adv-lead__title {
      padding: 12px 20px;
    }

    .adv-lead-key {
      flex-direction: column;

      .people-market__col {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .adv-lead-buttons {
      padding: 20px;
    }
  }

  .companyDiv {
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;

    .col-1 {
      width: 100%;
      margin-bottom: 30px;
    }
    .col-2 {
      width: 100%;
    }

    .uploads {
      padding: 0 20px;
    }
  }

  .people {
    width: 100%;
    padding: 20px;
  }

  .lead-search {
    .lead-search__title {
      padding: 12px 20px;
    }

    .lead-search__container {
      padding: 40px 20px 40px 0;
    }

    .lead-search-filters {
      flex-direction: column;

      .lead-search-key {
        width: 100%;
      }

      .w-50 {
        width: 100%;
      }

      .lead-search__buttons {
        margin-left: 20px;
      }

      .lead-search__keywords {
        padding: 0 20px;
      }

      .lead-search__past-history {
        padding-left: 20px;
        margin-bottom: 40px;
      }
    }
  }

  .result-page__table {
    min-width: 350px;

    &.expended {
      min-width: 600px;
    }
  }

  .result-header__top {
    padding: 12px 20px;
  }

  .search-tabs {
    padding: 20px 0px;
  }

  .person-dt-top {
    .person-dt-top__col {
      width: 100%;
      margin-bottom: 30px;

      .add-lead-panel {
        max-width: 100%;
      }

      .person-dt-top__box {
        height: auto;
      }
    }

    .person-dt-experience-salay {
      margin-top: 0px;
      flex-direction: column;

      .person-dt-top__col {
        &:last-child {
          margin-bottom: 0px;
        }
      }

      .person-dt-top__title {
        margin-bottom: 10px;
      }
    }
  }

  .person-dt-bottom {
    flex-direction: column-reverse;

    .person-dt-bottom__col {
      width: 100%;
      margin-bottom: 30px;
    }

    .person-dt-top__box {
      height: auto;
    }
  }

  .company-dt {
    .details {
      flex-direction: column;

      .details-data {
        width: 100%;
        margin-bottom: 30px;
      }

      .company-tab__row {
        flex-direction: column;

        .company-tab__col {
          max-width: 100%;

          .company-tab__link {
            width: 80%;
          }
        }
      }
    }
  }

  .activity__col {
    &.lead {
      width: 100%;
    }
  }

  .ignore-add {
    padding: 20px;
  }

  .ignore-search {
    padding: 20px 10px;
  }

  .ignore-table {
    .ignore-header__items {
      justify-content: space-between;
    }

    .ignore-list__items {
      justify-content: space-between;
    }

    .ignore-header__col.added {
      display: none;
    }

    .ignore-header__col.upload {
      display: none;
    }
  }

  // .lead-cont-item__dots {
  //   margin-left: 30px;
  // }

  .inside-list-panel {
    .markbased__table {
      min-width: 600px;
    }

    .inside-select {
      right: 0;
    }
  }

  .apiKey-panel {
    .coupon-list__row {
      justify-content: space-between;
    }

    .coupon-header__row {
      justify-content: space-between;
    }

    .apiKey_created_date {
      display: none;
    }

    .apiKey_revoked_date {
      display: none;
    }
  }

  .green {
    flex-direction: column;
    padding: 30px 25px;

    .green__text {
      margin-bottom: 10px;
    }
  }

  .profile {
    .profile__title {
      padding: 17px 20px;
    }

    .profile__content {
      padding: 30px 20px;

      .profile__row {

        &.profile-row1 {
          flex-direction: column-reverse;
        }

        .profile__col {
          width: 100%;
        }
      }
    }

    .soc-popup__box {
      padding: 0 20px;
    }
  }

  .users-tab-table {
    .users-tab-header__items {
      justify-content: space-between;
    }

    .users-tab-list__items {
      justify-content: space-between;
    }

    .users_created_date {
      display: none;
    }

    .users_last_login {
      display: none;
    }

    .users-tab-dropdown .dropdown-start {
      left: -160px;
    }

    .user-general {
      .details-cont__row {
        .details-cont__col {
          width: 100%;
        }
      }
    }

  }

  .billing-stat {
    flex-wrap: wrap;
    padding: 0 15px;

    &__col {
      width: 50%;
      margin-bottom: 10px;
    }

    &__item {
      flex-direction: column-reverse;
    }

    &__num {
      width: 100%;
      padding-top: 10px;
    }

    &__title {
      width: 100%;
      padding-left: 0;
      padding-bottom: 10px;
      border-left: none;
      border-bottom: 1px solid rgba(236, 151, 29, 0.3);
      text-align: center;
    }
  }

  .billing-tabs {
    margin: 0 15px;
    justify-content: space-evenly;

    &__name {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .billing-user-search {
    padding: 15px;

    p {
      display: none;
    }
  }

  .billing-user {

    .result-header__top {
      padding-right: 20px;
    }

    .billing-user-item {
      padding: 10px;
    }

    .billing-user-head {
      padding: 10px;

      &__name {
        width: 33%;

        &.number-of-lists {
          display: none;
        }

        &.last-login-date {
          display: none;
        }

        &.max-leads {
          display: none;
        }
      }
    }

    .billing-user-table {
      .col {
        width: 33%;

        &.number-of-lists {
          display: none;
        }

        &.last-login-date {
          display: none;
        }

        &.max-leads {
          display: none;
        }
      }
    }
  }

  .users-tab-search {
    padding: 15px;
  }

  .users-tab {
    &-header {
      padding: 15px 10px;
    }

    .coupon-header {
      padding: 15px 10px;
    }
  }
}
