.popout-result {
  &_active {
    position: relative;
    z-index: 20;
    margin-left: -10px;
    margin-right: -10px;
    transition: all 200ms ease;

    .popout-result__overlay {
      display: block;
    }
  }

  &__overlay {
    display: none;
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
