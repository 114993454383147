$orange: #ec971d;
$orange-light: #fdf4e8;
$success: #3fbb28;
$success-light: #F5FAF4;
$blue: #41b3ff;
$red: #E01817;
$danger: #E01817;
$danger-light: #fef5f4;
$dark-gray: #333333;
$gray: #bdbdbd;
$gray-light: #f6f6f6;
$white: #ffffff;
$border: 1px solid rgba(0, 0, 0, 0.1);
$font: 'IBM Plex Sans', sans-serif;
$grad: linear-gradient(180deg, #ff9c39 0%, #ff7700 100%);
$pressed-primary-btn:#d47d00;
$pressed-secondary-btn:#fff;
$darkest-opacity:1;
$dark-opacity:0.95;
$normal-opacity:0.9;
$bright-opacity:0.85;